import React, { Component } from "react";
import RegionesYGenero from "./components/RegionesYGenero.jsx";
import Leaflet from "./components/Leaflet.jsx";
import Apple from "../oldComponents/Apple.jsx";
import Vacunacion from "./components/Vacunacion.jsx";
import Camas from "./components/Camas.jsx";
import Casos from "./components/Casos.jsx";
import Positividad from "./components/Positividad.jsx";
// import ActivosYMuertes from "./components/ActivosYMuertes.jsx";
import RegionsHistoric from "./components/RegionsHistoric.jsx";
import { ReactSVG } from "react-svg";
import infectadosSVG from "../../images/infectados.svg";
import nuevosinfecSVG from "../../images/nuevos-infec.svg";
import recuperadoSVG from "../../images/recuperado.svg";
import stretcherSVG from "../../images/stretcher.svg";
// import logoSVG from "../../images/logo.svg";

class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div id="intro" className="column-holder">
          <div className="col-12 col-xl-4" id="intro-des">
            <div
              className="chart-info-side"
              style={{
                backgroundColor: "transparent",
                justifyContent: "flex-end",
                boxShadow: "none",
              }}
            >
              <div
                className="chart-info-side-holder"
                style={{ marginTop: "30%", paddingTop: "0%" }}
              >
                <div className="chart-info-side-des">
                  <div className="chart-info-side-des-title-holder">
                    <h1 className="main-title">
                      Covid-19 <br /> en Chile
                    </h1>
                  </div>
                  <br />
                  <div className="chart-info-side-des-title-holder">
                    <h3>
                      La primera linea <br />
                      hace todo lo posible.
                      <br />
                      ¡Ayúdanos! #quedateencasa
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-xl-8"
            style={{ backgroundColor: "rgba(255,254,248)" }}
          >
            <div id="resumen">
              <h3 className="wine">
                Resumen actualizado a la fecha:
                <br />
                22/05/2022
              </h3>
            </div>
            <div className="column-holder dashboard-holder">
              <div className="col-6 intro-data-block">
                <div
                  className="col-6 intro-data-block-card"
                  style={{ backgroundColor: "rgb(255, 163, 143)" }}
                >
                  <ReactSVG src={infectadosSVG} />
                  <h4 className="wine">
                    POSITIVIDAD: <br />
                    <strong>8.70%</strong>
                  </h4>
                </div>
              </div>
              {/* ↓ ↑ */}
              <div className="col-6 intro-data-block">
                <div
                  className="col-6 intro-data-block-card"
                  style={{
                    backgroundColor: "rgb(250, 198, 62)",
                    width: "250px",
                  }}
                >
                  <ReactSVG src={nuevosinfecSVG} />
                  <h4 className="wine">
                    CASOS NUEVOS: <br />
                    <strong>5.443</strong>
                  </h4>
                </div>
              </div>
              {/* ↓ ↑ */}
              <div className="col-6 intro-data-block">
                <div
                  className="col-6 intro-data-block-card"
                  style={{ backgroundColor: "rgb(136, 207, 209)" }}
                >
                  <ReactSVG src={recuperadoSVG} />
                  <h4 className="wine">
                    ACTIVOS: <br />
                    <strong>26.128</strong>
                  </h4>
                </div>
              </div>

              <div className="col-6 intro-data-block">
                <div
                  className="col-6 intro-data-block-card"
                  style={{ backgroundColor: "rgb(225, 134, 160)" }}
                >
                  <ReactSVG src={stretcherSVG} />
                  <h4 className="wine">
                    FALLECIDOS NUEVOS: <br />
                    <strong>8</strong>
                  </h4>
                </div>
              </div>
            </div>
            <h4 className="cc" style={{ padding: "40px" }}>
              <p>
                Toda la información correspondiente a Chile viene del Minsal.
                <br />
                Datos actualizados diariamente. <br />
                La metodología de recuperados, activos y fallecidos cambió los
                días 01/06 y 07/06. Un grupo de casos nuevos fue agregado el
                17/06 y de fallecidos nuevos el 17/07. Los datos pueden no
                coincidir temporalmente por el cambio de metodología.
              </p>
            </h4>
          </div>
        </div>

        {/* <ActivosYMuertes /> */}
        <Casos />
        <Positividad />
        <Camas />
        <Vacunacion />
        <RegionsHistoric />
        <Leaflet />
        <RegionesYGenero />
        <Apple />
      </>
    );
  }
}

export default Body;
