import React from "react";
import './App.css';
import Footer from './components/Footer/view'
import 'semantic-ui-css/semantic.min.css';
import './styles/style.scss';
/* import './styles/main.css'; */
import 'bootstrap/dist/css/bootstrap.min.css'
import Body from './components/Body/view'

class App extends React.Component {
  render() {
    return (
      <>
        <Body/>
        <Footer />
      </>
    );
  }
}

export default App;
