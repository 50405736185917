import React, { Component } from "react";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
import P2 from "../../../json/P2.json";

class SimpleExample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: -33.4569397,
      lng: -70.6482697,
      zoom: 9,
      typeChart: "Activos",
    };
  }

  handleTypeChange = (event) => {
    let typesList = ["Activos", "Activos 24/07", "Activos 26/06", "Activos 26/05", "Activos 24/04", "Activos 24/03", "Activos 24/02"];
    let val = event.target.selectedIndex;
    this.setState({
      typeChart: typesList[val],
    });
  };

  geoJSONStyle = (feature, layer) => {
    let variable = feature.properties.current
    let adjust = 75

    let pop = feature.properties.Poblacion / 100000

    let val = Math.round(Math.sqrt((10*variable) / pop)) / adjust;

    return {
      color: "rgba(0, 0, 0, 0.7)",
      weight: 2,
      fillOpacity: 0.7,
      fillColor: `rgb(${(val < 0.5 ? val * 2 : 1) * 255}, ${
        255 - (val > 0.5 ? (val - 0.5) * 2 : 0) * 255
      }, 0)`,
    };
  };

  onEachFeature = (feature, layer) => {
    const popupContent = `
    ${feature.properties.Name}
    <br />
    Activos por 100 mil hab: ${ Math.round((feature.properties.current / (feature.properties.Poblacion / 100000)) * 100) / 100}`
    //<br />
    //Actuales: ${ Math.round((feature.properties.current / (feature.properties.Poblacion / 100000)) * 100) / 100}
    // <br />
    // 24/07: ${ Math.round((feature.properties.current_2407 / (feature.properties.Poblacion / 100000)) * 100) / 100}
    // <br />
    // 26/06: ${ Math.round((feature.properties.current_2606 / (feature.properties.Poblacion / 100000)) * 100) / 100}
    // <br />
    // 26/05: ${ Math.round((feature.properties.current_2605 / (feature.properties.Poblacion / 100000)) * 100) / 100}
    // <br />
    // 24/04: ${ Math.round((feature.properties.current_2404 / (feature.properties.Poblacion / 100000)) * 100) / 100}
    // <br />
    // 24/03: ${ Math.round((feature.properties.current_2403 / (feature.properties.Poblacion / 100000)) * 100) / 100}
    // <br />
    // 24/02: ${ Math.round((feature.properties.current_2402 / (feature.properties.Poblacion / 100000)) * 100) / 100}
    ;
    layer.bindPopup(popupContent);
  };

  render() {
    const { typeChart } = this.state;
    const position = [this.state.lat, this.state.lng];
    return (
      <div className="column-holder">
        <div className="col-12 col-xl-4 chart-info-side">
          <div className="chart-info-side-holder">
            <div className="chart-info-side-des">
              <div className="chart-info-side-des-title-holder">
                <h3>Casos por Comuna</h3>
              </div>
              <h5>
                Escala de color: {typeChart} por cada 100.000 habitantes <br />
                Verde: menos casos, Rojo: mas casos
              </h5>
              <div className="dropdown-share-container">
                Seleccione el tipo:
                <div className="dropdown">
                  <select
                    name="term"
                    value={typeChart}
                    onChange={(event) => this.handleTypeChange(event)}
                  >
                    <option id="Activos">Activos</option>
                    {/* <option id="Activos 24/07">Activos 24/07</option>
                    <option id="Activos 26/06">Activos 26/06</option>
                    <option id="Activos 26/05">Activos 26/05</option>
                    <option id="Activos 24/04">Activos 24/04</option>
                    <option id="Activos 24/03">Activos 24/03</option>
                    <option id="Activos 24/02">Activos 24/02</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-8">
          <div
            className="container-js"
            style={{ backgroundColor: "rgba(255,254,248)" }}
          >
            <div
              className="section-js"
              id="chart"
              style={{ position: "relative" }}
            >
              <div className="background-holder-charts"></div>

              <div className="section-js-chart-holder">
                <Map center={position} zoom={this.state.zoom}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
                  />
                  <GeoJSON
                    data={P2}
                    style={this.geoJSONStyle}
                    onEachFeature={this.onEachFeature}
                  />
                </Map>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SimpleExample;
