import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class RegionesYGenero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "Sí",
      termNumber: 0,
      hab: "No",
      habNumber: 1,
      type: "Casos",
      typeNumber: 0,
    };
  }

  handleTermChange = (term) => {
    let termsList = ["Sí", "No"];
    let termNumber = termsList.indexOf(term);
    this.setState({
      term: term,
      termNumber: termNumber,
    });
  };

  handleChangeHab = (hab) => {
    let habList = ["Sí", "No"];
    let habNumber = habList.indexOf(hab);
    this.setState({
      hab: hab,
      habNumber: habNumber,
    });
  };

  handleChangeType = (type) => {
    let typeList = ["Casos", "Fallecidos"];
    let typeNumber = typeList.indexOf(type);
    this.setState({
      type: type,
      typeNumber: typeNumber,
    });
  };

  render() {
    const { term, termNumber, hab, type } = this.state;

    let total = [];
    let deaths = [];
    let deathsPer = [];
    let totalPer = [];
    let newOnes = [];
    let oldOnes = [];
    let newOnesPer = [];
    let oldOnesPer = [];
    let regions = [];

    let totalN = [];
    let totalPerN = [];
    let regionsN = [];

    let dat = [
      {
        region: "Arica y Parinacota",
        total: 55973 ,
        nuevos: 42 ,
        fallecidos: 812 ,
        notif: 318 ,
        pob: 2.5211 ,
        },
        {
        region: "Tarapacá",
        total: 80233 ,
        nuevos: 26 ,
        fallecidos: 1088 ,
        notif: 325 ,
        pob: 3.82773 ,
        },
        {
        region: "Antofagasta",
        total: 124913 ,
        nuevos: 123 ,
        fallecidos: 1766 ,
        notif: 815 ,
        pob: 6.91854 ,
        },
        {
        region: "Atacama",
        total: 67183 ,
        nuevos: 76 ,
        fallecidos: 588 ,
        notif: 197 ,
        pob: 3.14709 ,
        },
        {
        region: "Coquimbo",
        total: 129420 ,
        nuevos: 184 ,
        fallecidos: 1680 ,
        notif: 503 ,
        pob: 8.36096 ,
        },
        {
        region: "Valparaíso",
        total: 317266 ,
        nuevos: 778 ,
        fallecidos: 5628 ,
        notif: 2340 ,
        pob: 19.6017 ,
        },
        {
        region: "Metropolitana",
        total: 1411202 ,
        nuevos: 2915 ,
        fallecidos: 28360 ,
        notif: 17567 ,
        pob: 81.25072 ,
        },
        {
        region: "O’Higgins",
        total: 153030 ,
        nuevos: 180 ,
        fallecidos: 2525 ,
        notif: 619 ,
        pob: 9.91063 ,
        },
        {
        region: "Maule",
        total: 243837 ,
        nuevos: 319 ,
        fallecidos: 2926 ,
        notif: 1431 ,
        pob: 11.31939 ,
        },
        {
        region: "Ñuble",
        total: 102257 ,
        nuevos: 107 ,
        fallecidos: 1116 ,
        notif: 1380 ,
        pob: 5.11551 ,
        },
        {
        region: "Biobío",
        total: 358474 ,
        nuevos: 386 ,
        fallecidos: 4290 ,
        notif: 2450 ,
        pob: 16.63696 ,
        },
        {
        region: "Araucanía",
        total: 218381 ,
        nuevos: 135 ,
        fallecidos: 2580 ,
        notif: 1416 ,
        pob: 10.14343 ,
        },
        {
        region: "Los Ríos",
        total: 106885 ,
        nuevos: 51 ,
        fallecidos: 1225 ,
        notif: 669 ,
        pob: 4.05835 ,
        },
        {
        region: "Los Lagos",
        total: 196142 ,
        nuevos: 54 ,
        fallecidos: 2326 ,
        notif: 1036 ,
        pob: 8.9144 ,
        },
        {
        region: "Aysén",
        total: 25292 ,
        nuevos: 14 ,
        fallecidos: 185 ,
        notif: 37 ,
        pob: 1.07297 ,
        },
        {
        region: "Magallanes",
        total: 52605 ,
        nuevos: 53 ,
        fallecidos: 705 ,
        notif: 535 ,
        pob: 1.78362 ,
        },
        
    ];

    dat =
      type === "Casos"
        ? dat.sort((a, b) =>
            a.total < b.total
              ? 1
              : a.total === b.total
              ? a.nuevos < b.nuevos
                ? 1
                : -1
              : -1
          )
        : dat.sort((a, b) =>
            a.fallecidos < b.fallecidos
              ? 1
              : a.fallecidos === b.fallecidos
              ? a.pob < b.pob
                ? 1
                : -1
              : -1
          );

    var i;
    for (i = 0; i < dat.length; i++) {
      total.push(dat[i].total);
      newOnes.push(dat[i].nuevos);
      deaths.push(dat[i].fallecidos);
      oldOnes.push(dat[i].total - dat[i].nuevos);
      totalPer.push(Math.round((dat[i].total / dat[i].pob) * 100) / 100);
      deathsPer.push(Math.round((dat[i].fallecidos / dat[i].pob) * 100) / 100);
      newOnesPer.push(Math.round((dat[i].nuevos / dat[i].pob) * 100) / 100);
      oldOnesPer.push(
        Math.round(((dat[i].total - dat[i].nuevos) / dat[i].pob) * 100) / 100
      );
      regions.push(dat[i].region);
      totalN.push(dat[i].notif);
      totalPerN.push(Math.round((dat[i].notif / dat[i].pob) * 100) / 100);
      regionsN.push(dat[i].region);
    }

    let totalNumber = total.length;
    total =
      hab === "No"
        ? total.slice(termNumber, totalNumber)
        : totalPer.slice(termNumber, totalNumber);
    const options = {
      maintainAspectRatio: false,
      legend: {
        position: "top",
        labels: {
          fontSize: 15,
          fontcolor: "rgba(0, 0, 0, 1)",
        },
      },
      tooltips: {
        mode: "label",
        callbacks:
          type === "Casos"
            ? {
                afterBody: function (t, d) {
                  return "Total: " + total[t[0].index];
                },
              }
            : {
                afterBody: function (t, d) {
                  return null;
                },
              },
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            ticks: {
              autoSkip: false,
              fontSize: 15,
              fontColor: "black",
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            ticks: {
              fontSize: 15,
              fontColor: "black",
            },
          },
        ],
      },
    };

    let dataC = {
      datasets: [
        {
          label: "Casos Previos",

          data:
            hab === "No"
              ? oldOnes.slice(termNumber, totalNumber)
              : oldOnesPer.slice(termNumber, totalNumber),
          backgroundColor: "rgba(127, 127, 127, 0.7)",
          borderColor: "rgba(127, 127, 127, 0.7)",
        },
        {
          label: "Casos Nuevos",
          data:
            hab === "No"
              ? newOnes.slice(termNumber, totalNumber)
              : newOnesPer.slice(termNumber, totalNumber),
          backgroundColor: "rgba(255, 195, 0, 0.7)",
          borderColor: "rgba(255, 195, 0, 0.7)",
        },
      ],
      labels: regions.slice(termNumber, totalNumber),
    };

    let dataF = {
      datasets: [
        {
          label: "Fallecidos",

          data:
            hab === "No"
              ? deaths.slice(termNumber, totalNumber)
              : deathsPer.slice(termNumber, totalNumber),
          backgroundColor: "rgba(127, 127, 127, 0.7)",
          borderColor: "rgba(127, 127, 127, 0.7)",
        },
      ],
      labels: regions.slice(termNumber, totalNumber),
    };

    let data = type === "Casos" ? dataC : dataF;

    return (
      <div className="column-holder">
        <div className="col-12 col-xl-4 chart-info-side">
          <div className="chart-info-side-holder">
            <div className="chart-info-side-des">
              <div className="chart-info-side-des-title-holder">
                <h3>Casos por Región</h3>
              </div>
              <h5>
                Opciones: Casos Previos y Nuevos, Totales, Por cada 100 mil
                Habitantes y Fallecidos.
              </h5>
              <div className="dropdown-share-container">
                <span>Incluir R. M.: </span>
                <div className="dropdown">
                  <select
                    name="term"
                    value={term}
                    onChange={(event) =>
                      this.handleTermChange(event.target.value)
                    }
                  >
                    <option id="0">Sí</option>
                    <option id="1">No</option>
                  </select>
                </div>
              </div>
              <div className="dropdown-share-container">
                <span>Por 100 mil Habitantes: </span>
                <div className="dropdown">
                  <select
                    name="term"
                    value={hab}
                    onChange={(event) =>
                      this.handleChangeHab(event.target.value)
                    }
                  >
                    <option id="0">Sí</option>
                    <option id="1">No</option>
                  </select>
                </div>
              </div>
              <div className="dropdown-share-container">
                <span>Dato:</span>
                <div className="dropdown">
                  <select
                    name="term"
                    value={type}
                    onChange={(event) =>
                      this.handleChangeType(event.target.value)
                    }
                  >
                    <option id="0">Casos</option>
                    <option id="1">Fallecidos</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-8">
          <div
            className="container-js"
            style={{ backgroundColor: "rgba(255,254,248)" }}
          >
            <div
              className="section-js"
              id="chart"
              style={{ position: "relative" }}
            >
              <div className="background-holder-charts"></div>

              <div className="section-js-chart-holder">
                <Bar data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegionesYGenero;
