import React, { Component } from 'react'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
        <footer>
                    <div id="footer" className="column-holder">
                        <div className="col-12 col-xl-4 chart-info-side"  style={{paddingTop: "0px"}}>
                    </div>
                    <div className="col-12 col-xl-8 footer-info">
                    <div>
                        <h3>Contáctame: <a href="https://www.linkedin.com/in/jsusran/" target="_blank" rel="noopener noreferrer">Jesus Rangel</a></h3>
                    </div>
                </div>
            </div>
        </footer>
        )
    }
}

export default Footer